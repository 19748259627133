import React from "react";

import { Container } from "common/src/reusecore/Layout";
import SectionTitle from "common/src/reusecore/SectionTitle";
import Button from "common/src/reusecore/Button";
import { track } from 'common/src/reusecore/Tracking';

import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

import ContactWrapper from "./contact.style";
import { useTranslation, Trans } from 'common/src/reusecore/Trans';

import countries from 'i18n-iso-countries';


class ContactBig extends React.Component {
  componentDidMount() {
    const lng = i18next.language.substr(0, 2);
    let countryNames = countries.getNames(lng);
    if (Object.keys(countryNames).length == 0) {
      countryNames = countries.getNames('en');
    }
    const countryObjs = [];
    for (const code in countryNames) {
      const names = countryNames[code];
      countryObjs.push({code: code.toLowerCase(), name: Array.isArray(names) ? names[0] : names});
    }
    countryObjs.sort((a, b) => {
      if (a.name.localeCompare)
        return a.name.localeCompare(b.name);
      return a.name > b.name ? -1 : 1;
    });
    this.setState({countryObjs});
  }

  render() {
    const {t} = this.props;
    const lng = i18next.language.substr(0, 2);
    const countryObjs = this.state?.countryObjs || [];
    
    return (
      <ContactWrapper id="contact">
        <SectionTitle className="section-title" UniWidth="55%">
          <h4><Trans i18nKey="subscribe.announcement.0" /> &mdash; <Trans i18nKey="subscribe.announcement.1" /></h4>
          <h2>
            <Trans i18nKey="subscribe.contact_title" />
          </h2>
        </SectionTitle>
        <Container>
          <div className="contact-email">
            <form method="post" action="/subscribe" id="form-contact">
              <h5><Trans i18nKey="subscribe.name" /></h5>
              <div className="contact-row">
                <input type="text" name="first_name" placeholder={t('subscribe.first_name')} id="first_name-contact" />
                <input type="text" name="last_name" placeholder={t('subscribe.last_name')} id="last_name-contact" />
              </div>
              <h5><Trans i18nKey="subscribe.email" />*</h5>
              <div className="contact-row">
                <input type="email" name="email" placeholder={t('subscribe.email')} id="email-contact" />
              </div>
              <h5><Trans i18nKey="subscribe.location" /></h5>
              <div className="contact-row">
                <select defaultValue={lng.toLowerCase()} name="country">
                  <option>{t('subscribe.country')}</option>
                  <option>---</option>
                  {countryObjs.map(({code, name}) => <option value={code}>{name}</option>)}
                </select>
                <input type="text" name="zip_code" placeholder={t('subscribe.zip_code')} id="zip_code-contact" />
              </div>
              <input type="hidden" name="captcha" id="captcha-contact" />
              <input type="hidden" name="lng" id="lng-contact" />
              <Button
                className="banner-btn one"
                onClick={e => {
                  e.preventDefault();
                  const email = document.getElementById('email-contact').value;
                  track('subscribeContact', {email});
                  if (email.length == 0)
                    return;
                  grecaptcha.ready(function() {
                    grecaptcha.execute('6LfPnPwUAAAAALmsYoJ2rqC_OJ2FnEz8CK9Ppfcr', {action: 'submit'}).then(function(token) {
                      document.getElementById('captcha-contact').value = token;
                      document.getElementById('lng-contact').value = i18next.language;
                      document.getElementById('form-contact').submit();
                    });
                  });
                }}
              >
                <Trans i18nKey="subscribe.action" />
              </Button>
              <div className="privacy">
                <Trans i18nKey="subscribe.disclaimer">
                  By clicking subscribe you agree to our <a href="/privacy">privacy policy</a>.
                </Trans>
              </div>
            </form>
          </div>
        </Container>
      </ContactWrapper>
    );
  }
};
ContactBig = withTranslation()(ContactBig);

export default ContactBig;
